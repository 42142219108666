import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/App/App';
import { isNative } from './util/isNative';
import capacitorInit from './util/capacitorInit';
import './util/i18n';
import './util/fontAwesome';
import './index.scss';
import './EULA.html';
import './assets/apple-app-site-association';
import './assets/assetlinks';
import './assets/fonts/index.css';
import { Toaster } from 'react-hot-toast';

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Toaster />
      <App />
    </Provider>,
    document.getElementById('app'),
  );
};

if (isNative) {
  document.addEventListener(
    'deviceready',
    () => {
      startApp();
      capacitorInit();
    },
    false,
  );
} else {
  startApp();
}