import getUserLocale from 'get-user-locale';
import { isNative } from './isNative';
export var LOCALE = getUserLocale().toLowerCase();
export var SUPPORT_EMAIL = 'info@invictusgames.org';
var DOMAIN_DEVELOP = 'http://localhost:8000';
var DOMAIN_STAGING = 'https://staging.api-v1.igfwis.com';
var DOMAIN_PRODUCTION = 'https://api-v1.igfwis.com';
var WS_DOMAIN_DEVELOP = 'ws://localhost:8000';
var WS_DOMAIN_STAGING = 'wss://staging.api-v1.igfwis.com';
var WS_DOMAIN_PRODUCTION = 'wss://api-v1.igfwis.com';
var apiDomain = DOMAIN_DEVELOP;
var wsDomain = WS_DOMAIN_DEVELOP;
var env = process.env.NODE_ENVIRONMENT;
if (env === 'staging') {
    apiDomain = DOMAIN_STAGING;
    wsDomain = WS_DOMAIN_STAGING;
}
else if (env === 'production') {
    apiDomain = DOMAIN_PRODUCTION;
    wsDomain = WS_DOMAIN_PRODUCTION;
}
export var FEATURE_FLAGS = {
    boards: true,
    savedPosts: false,
    likedPosts: true,
    forgotPassword: true,
    storeLinks: !isNative,
    googleTranslate: true,
    notifications: true,
    chat: true,
    chatLikes: false,
};
export var ENVIRONMENT = env;
export var API_DOMAIN = apiDomain;
export var WS_API_DOMAIN = wsDomain;
