import { Preferences } from '@capacitor/preferences';
import api from '../util/api';
import { useState } from 'react';

const useGoogleTranslate = text => {
  const storedLanguage = void Preferences.get({
    key: 'language',
  });
  const language = storedLanguage || navigator.language;
  const target = language.split('-')[0];

  const [translated, setTranslated] = useState(false);
  const [translation, setTranslation] = useState(null);
  const [translationError, setTranslationError] = useState(null);
  const translate = () => {
    api
      .translate(text, target)
      .then(result => {
        setTranslated(true);
        setTranslation(result.data.translations[0].translatedText);
      })
      .catch(error => setTranslationError(error.message));
  };

  return {
    translate,
    translated,
    translation,
    original: text,
    translationError,
  };
};

export default useGoogleTranslate;
