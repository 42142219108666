import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  commentUnpublishDispatch,
} from '../redux/modules/comment';


function useCommentRemove(postId, commentId) {
  const dispatch = useDispatch();
  const {
    isUnpublishing,
    unpublishedSinceLastFetched,
    unPublishError,
  } = useSelector(({ comments }) => ({
    isUnpublishing: comments.commentsByPostId[postId].isUnpublishing,
    unpublishedSinceLastFetched: comments.commentsByPostId[postId].unpublishedSinceLastFetched,
    unPublishError: comments.commentsByPostId[postId].unPublishError,
  }));
  const [removed, setIsRemoved] = useState(false);
  const [optimisticRemove, setOptimisticRemoved] = useState(false);

  useEffect(() => {
    const isRemoved = unpublishedSinceLastFetched?.includes(commentId);
    setIsRemoved(isRemoved);
  }, [commentId, unpublishedSinceLastFetched]);

  useEffect(() => setOptimisticRemoved(false), [unPublishError]);

  const removeComment = () => {
    setOptimisticRemoved(true);
    dispatch(commentUnpublishDispatch(postId, commentId));
  };

  return {
    removeComment,
    removed,
    isRemoving: isUnpublishing,
    optimisticRemove,
  };
}

export default useCommentRemove;
