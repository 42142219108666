import { API_DOMAIN, ENVIRONMENT, LOCALE } from './config';
import APIDjango from './APIDjango';
import apiStub from './apiStub';
import { stubArray } from 'lodash';

const config = {
  domain: API_DOMAIN,
  locale: LOCALE,
};

const API_DJANGO = new APIDjango(config);
const API_STUB = apiStub({ locale: LOCALE });

let API = API_DJANGO;

if (ENVIRONMENT === 'staging') {
  API = API_DJANGO;
} else if (ENVIRONMENT === 'production') {
  API = API_DJANGO;
}

const api = {
  signIn: API.signIn,
  getToken: API.getToken,
  flagChatMessage: API.flagChatMessage,
  getHiddenChatMessage: API.getHiddenChatMessage,
  hideChatMessage: API.hideChatMessage,
  deleteChatMessage: API.deleteChatMessage,
  likeChatMessage: API.likeChatMessage,
  removeLikeChatMessage: API.removeLikeChatMessage,
  getFlaggedMessage: API.getFlaggedMessage,
  resumeSession: API.resumeSession,
  signOut: API.signOut,
  signUp: API.signUp,
  uploadFile: API.uploadFile,
  uploadVideoToAPI: API.uploadVideoToAPI,
  verifyVideoUpload: API.verifyVideoUpload,
  videoAbortUpload: API.videoAbortUpload,
  uploadRequiredDocuments: API.uploadRequiredDocuments,
  forgotPassword: API.forgotPassword,
  resetPassword: API.resetPassword,
  profileGet: API.profileGet,
  updateInterests: API.updateInterests,
  notificationsGetHistory: API.notificationsGetHistory,
  // notificationsGetRead: API.notificationsGetRead,
  notificationsUpdateRead: API.notificationsUpdateRead,
  notificationsGetPreferences: API.notificationsGetPreferences,
  notificationsUpdatePreferences: API.notificationsUpdatePreferences,
  updateAvatar: API.updateAvatar,
  updateFirebaseToken: API.updateFirebaseToken,
  boardGetAll: API.boardGetAll,
  getPosts: API.getPosts,
  getPostsIncludingGlobal: API.getPostsIncludingGlobal,
  getMentionableUsersForBoard: API.getMentionableUsersForBoard,
  getMentionableUsersForChat: API.getMentionableUsersForChat,
  commentsForPost: API.commentsForPost,
  createPost: API.createPost,
  uploadVideoChunk: API.uploadVideoChunk,
  getChunkUploadArray: API.getChunkUploadArray,
  unpublishPost: API.unpublishPost,
  unpublishComment: API.unpublishComment,
  createComment: API.createComment,
  blockUser: API.blockUser,
  unblockUser: API.unblockUser,
  savePost: API_STUB.savePost,
  unSavePost: API_STUB.unSavePost,
  likePost: API.likePost,
  unLikePost: API.likePost,
  flagContent: API.flagContent,
  chatRooms: API.chatRooms,
  chatRoomById: API.chatRoomById,
  chatMessages: API.chatMessages,
  createChatMessage: API.createChatMessage,
  chatMessageById: API.chatMessageById,
  taxonomyEthnicity: API.taxonomyEthnicity,
  taxonomyGender: API.taxonomyGender,
  taxonomyCountry: API.taxonomyCountry,
  taxonomyMilitaryStatus: API.taxonomyMilitaryStatus,
  taxonomyFlagReasons: API.taxonomyFlagReasons,
  taxonomyMilitaryService: API.taxonomyMilitaryService,
  taxonomyPastGames: API.taxonomyPastGames,
  taxonomyPastTrials: API.taxonomyPastTrials,
  taxonomyOpportunities: API.taxonomyOpportunities,
  taxonomyMedicalStatus: API.taxonomyMedicalStatus,
  taxonomyGameInvolvement: API.taxonomyGameInvolvement,
  translate: API.translate,
  autoTranslate: API.autoTranslate,
  getPostCategories: API.getPostCategories,
  getPostCategoriesNew: API.getPostCategoriesNew,
};

export default api;
