import { jsx as _jsx } from "react/jsx-runtime";
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { renderCheckboxButtonGroup } from '../../util/formik-custom-fields';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import { useTaxonomyOpportunities } from '../../util/APIDjango';
var OpportunityInterestField = function (_a) {
    var name = _a.name, label = _a.label;
    var t = useTranslation().t;
    var _b = useTaxonomyOpportunities(), opportunities = _b[0], setOpportunities = _b[1];
    // const { opportunitiesTerms } = useSelector(({ taxonomyOpportunities }: any) => ({
    //   opportunitiesTerms: taxonomyOpportunities.terms,
    // }));
    var handleRenderItems = function (_selectedValue, handleSelect, getSelectedState) { return (opportunities.terms.map(function (term) { return (_jsx(CheckboxButton, { id: "".concat(term.vocabulary, "-").concat(term.id), value: term.id, 
        // label={term.name}
        label: term.i18nKey ? t("".concat(term.i18nKey, ".label")) : term.description, checked: getSelectedState(term.id), onChange: function () { return handleSelect(term.id); } }, term.id)); })); };
    return (_jsx(Field, { name: name, label: label, component: renderCheckboxButtonGroup, multiSelect: true, renderItems: handleRenderItems }));
};
export default OpportunityInterestField;
