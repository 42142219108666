import React, { useEffect, useState } from 'react';
import {
  updateLanguagesDispatch,
  updateLanguagesFulfill,
  updateLanguagesReject,
} from '../../redux/modules/autoTranslate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Preferences } from '@capacitor/preferences';
import api from '../../util/api';
// import { getChatRoomsDispatch } from '../../redux/modules/chat';
import { getPostsDispatch } from '../../redux/modules/post';
import { dispatch as getProfile } from '../../redux/modules/profile';
import styles from './LanguageSelector.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useChatRooms } from '../../util/APIDjango';

const LanguageSelector = () => {
  const { i18n } = useTranslation('', { useSuspense: false });
  const [language, setLanguage] = useState(i18n.language);
  const dispatch = useDispatch();
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);
  const [chatRoomsData, setChatRoomsData] = useChatRooms();

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });

    void Preferences.get({ key: 'language' }).then(result => {
      if (result.value) {
        setLanguage(result.value);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, [language]);

  useEffect(() => {
    const handleLanguageChanged = lang => {
      setLanguage(lang);
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  const changeLanguage = e => {
    i18n.changeLanguage(e.target.value).catch(() => {
      console.error('Failed to change language');
    });
    void Preferences.set({
      key: 'language',
      value: e.target.value,
    });

    handleLanguagePreference(
      {
        language: e.target.value,
        autoTranslatePreference: autoTranslateStatus,
      },
      { setSubmitting: () => { }, setStatus: () => { } },
    );
  };

  const getChatRooms = () => setChatRoomsData({ ...chatRoomsData, isFetched: false, isFetching: true })

  const handleLanguagePreference = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    dispatch(updateLanguagesDispatch(values));
    api
      .autoTranslate(values)
      .then(response => {
        dispatch(updateLanguagesFulfill(response));
        dispatch(getPostsDispatch(null, null, null, 0, 10, true));
        // dispatch(getChatRoomsDispatch());
        getChatRooms();
      })
      .catch(error => {
        dispatch(updateLanguagesReject(error.message));
      });
  };

  return (
    <div className={styles.wrapper}>
      <FontAwesomeIcon icon={['fas', 'globe-europe']} />
      <select
        className={styles.lngSelect}
        onChange={changeLanguage}
        value={language}>
        <option key="en" value="en">
          English
        </option>
        <option key="ar-IQ" value="ar-JO">
          العربية (العراق)
        </option>
        <option key="ar-JO" value="ar-JO">
          العربية (الأردن)
        </option>
        <option key="da" value="da">
          Dansk
        </option>
        <option key="de" value="de">
          Deutsch
        </option>
        <option key="es-ES" value="es-ES">
          Español
        </option>
        <option key="et" value="et">
          Estonean
        </option>
        <option key="fa" value="fa">
          دری
        </option>
        <option key="fr" value="fr">
          Français
        </option>
        <option key="fr-BE" value="fr-BE">
          Français (Belge)
        </option>
        <option key="fr-CA" value="fr-CA">
          Français (Canadien)
        </option>
        <option key="it" value="it">
          Italiano
        </option>
        <option key="iw" value="iw">
          עברית
        </option>
        <option key="ka-GE" value="ka-GE">
          ქართული
        </option>
        <option key="ko" value="ko">
          한국어
        </option>
        <option key="nl" value="nl">
          Nederlands
        </option>
        <option key="nl-BE" value="nl-BE">
          Nederlands (Belgisch)
        </option>
        <option key="pl" value="pl">
          Polski
        </option>
        <option key="ps" value="ps">
          پښتو
        </option>
        <option key="ro" value="ro">
          Rumanian
        </option>
        <option key="uk" value="uk">
          Yкраїнська мова
        </option>
      </select>
    </div>
  );
};

export default LanguageSelector;
