import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCommentRemove from '../../hooks/useCommentRemove';
import UIListItem from '../UIListItem/UIListItem';
import HideShow from '../HideShow/HideShow';
import ActionButtonGroup from '../ActionButtonGroup/ActionButtonGroup';
import styles from './RemoveCommentListItem.module.scss';


const RemoveCommentListItem = ({ commentId, postId }) => {
  const {
    removeComment,
    removed,
    isRemoving,
    optimisticRemoved,
  } = useCommentRemove(postId, commentId);
  const { t } = useTranslation();

  const removeStatus = isRemoving ? optimisticRemoved : removed;
  const [showDecision, setShowDecision] = useState(false);
  const [label, setLabel] = useState(t('addSavedList.label'));
  useEffect(() => {
    if (isRemoving) {
      setLabel(t('removingComment.label'));
    } else if (removed) {
      setLabel(t('commentRemoved.label'));
    } else {
      setLabel(t('removeComment.label'));
    }
  }, [isRemoving, removeStatus]);

  const iconStyles = [
    styles.icon,
    isRemoving && styles.saving,
  ].join(' ');

  const icon = isRemoving
    ? <FontAwesomeIcon className={iconStyles} icon={['far', 'hourglass']} />
    : <FontAwesomeIcon className={iconStyles} icon={['fas', 'trash']} />;

  return (
    <Fragment>
      <UIListItem
        icon={icon}
        disabled={isRemoving}
        onClick={() => setShowDecision(true)}
      >
        {label}
      </UIListItem>
      <HideShow show={showDecision}>
        <div className={styles.decisionCopy}>
          <p>{t('sureRemove.label')}</p>
        </div>
        <ActionButtonGroup>
          <button type="button" onClick={() => setShowDecision(false)}>{t('no.label')}</button>
          <button type="button" onClick={removeComment}>{t('yes.label')}</button>
        </ActionButtonGroup>
      </HideShow>
    </Fragment>
  );
};

RemoveCommentListItem.propTypes = {
  commentId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
};

export default RemoveCommentListItem;
