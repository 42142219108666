// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-RemoveCommentListItem-module-icon-gECTF{transition:color ease .25s}.-RemoveCommentListItem-module-icon-gECTF.-RemoveCommentListItem-module-saving-S_epx{color:#ff9800}.-RemoveCommentListItem-module-decisionCopy-UeKZy{padding:0px 16px}`, "",{"version":3,"sources":["webpack://./src/components/RemoveCommentListItem/RemoveCommentListItem.module.scss"],"names":[],"mappings":"AAIA,0CACE,0BAAA,CAEA,qFACE,aAAA,CAIJ,kDACE,gBAAA","sourcesContent":["@import \"../../theme/colors\";\n@import \"../../theme/dimensions\";\n\n\n.icon {\n  transition: color ease .25s;\n\n  &.saving {\n    color: $warning;\n  }\n}\n\n.decisionCopy {\n  padding: 0px $gutter;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `-RemoveCommentListItem-module-icon-gECTF`,
	"saving": `-RemoveCommentListItem-module-saving-S_epx`,
	"decisionCopy": `-RemoveCommentListItem-module-decisionCopy-UeKZy`
};
export default ___CSS_LOADER_EXPORT___;
