import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import ar_IQ from '../assets/translations/ar_IQ.json';
import ar_JO from '../assets/translations/ar_JO.json';
import da from '../assets/translations/da.json';
import de from '../assets/translations/de.json';
import en from '../assets/translations/en.json';
import es_ES from '../assets/translations/es_ES.json';
import et from '../assets/translations/et.json';
import fa from '../assets/translations/fa.json';
import fr from '../assets/translations/fr.json';
import fr_BE from '../assets/translations/fr_BE.json';
import fr_CA from '../assets/translations/fr_CA.json';
import it from '../assets/translations/it.json';
import iw from '../assets/translations/iw.json';
import ka_GE from '../assets/translations/ka_GE.json';
import ko from '../assets/translations/ko.json';
import nl from '../assets/translations/nl.json';
import nl_BE from '../assets/translations/nl_BE.json';
import pl from '../assets/translations/pl.json';
import ps from '../assets/translations/ps.json';
import ro from '../assets/translations/ro.json';
import uk from '../assets/translations/uk.json';


export default i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      'ar-IQ': { translations: ar_IQ },
      'ar-JO': { translations: ar_JO },
      'da': { translations: da },
      'de': { translations: de },
      'en': { translations: en },
      'es-ES': { translations: es_ES },
      'et': { translations: et },
      'fa': { translations: fa },
      'fr': { translations: fr },
      'fr-BE': { translations: fr_BE },
      'fr-CA': { translations: fr_CA },
      'it': { translations: it },
      'iw': { translations: iw },
      'ka-GE': { translations: ka_GE },
      'ko': { translations: ko },
      'nl': { translations: nl },
      'nl-BE': { translations: nl_BE },
      'pl': { translations: pl },
      'ps': { translations: ps },
      'ro': { translations: ro },
      'uk': { translations: uk },
    },
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

i18n.on('languageChanged', () => {
  document.body.dir = i18n.dir();
});
