import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AutoTranslateDisclaimer from '../components/AutoTranslateDisclaimer/AutoTranslateDisclaimer';
import ChatDisclaimer from '../components/ChatDisclaimer/ChatDisclaimer';
import ChatRoomList from '../components/ChatRoomList/ChatRoomList';
import Layout from '../components/Layout/Layout';
import { Preferences } from '@capacitor/preferences';
import { useEffect } from 'react';
/**
 * Page which displays all of the chats a user can enter
 * @returns {JSX.Element}
 */
var AllChats = function () {
    //set default language to english if not set
    useEffect(function () {
        void Preferences.get({ key: 'language' }).then(function (result) {
            if (!result.value) {
                void Preferences.set({ key: 'language', value: 'en' });
            }
        });
    }, []);
    // const { chatId } = useParams<{ chatId?: string }>();
    return (_jsxs(Layout, { children: [_jsx(ChatRoomList, {}), _jsx(ChatDisclaimer, {}), _jsx(AutoTranslateDisclaimer, {})] }));
};
export default AllChats;
